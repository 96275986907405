import React from 'react';
import { CustomModal } from '../../shared/CustomModal';
import { Button, Table } from 'react-bootstrap';
import { calculateTotal } from '../../forms/Appointment';
import moment from 'moment';
import { SessionInvoice } from '../../pdf/Receipt';


export const SessionModal: React.FC<{ session: any, onClose: any, patient: any }> = ({ session, onClose, patient }) => {
  
  return (
    <CustomModal title="Detalle de Cita" onClose={onClose}>
      <div style={{height:600}} className='border border-danger'>
      <SessionInvoice session={session} patient={patient}/>
      </div>

      <div>


        <div className='mb-3'>
          <div className=' d-flex justify-content-between'>
            <span className='mb-0'>#Ref :</span> <span>{session.id}  </span>
          </div>
          <div className=' d-flex justify-content-between'>
            <span className='mb-0'>Fecha:</span> <span>{moment(session.date, 'YYYY-MM-DD').toDate().toLocaleDateString()}  </span>
          </div>
          <div className=' d-flex justify-content-between'>
            <span className='mb-0'>Paciente:</span> <span> {patient.first_name} {patient.last_name} </span>
          </div>
          <div className='d-flex justify-content-between'>
            <span className='mb-0'>Cedula:</span> <span> {patient.ci} </span>
          </div>
        </div>

        <Table size='sm'>
          <thead>
            <tr>
              <th colSpan={4} className='text-center'>Facturacion</th>
            </tr>
            <tr>
              <th>Descripcion</th>
              <th>Cantidad</th>
              <th>P/U</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {
              session.billables.map((billable: any) => (
                <tr>
                  <td>{billable.name}</td>
                  <td>{billable.quantity}</td>
                  <td>{billable.price}</td>
                  <td>{calculateTotal(billable)}</td>
                </tr>
              ))
            }
          </tbody>
          <tfoot>
            <tr style={{ fontWeight: 'bold' }}>
              <td colSpan={3}>Total</td>
              <td>{session.billables.reduce((acc: number, billable: any) => acc + calculateTotal(billable), 0)}</td>
            </tr>
          </tfoot>
        </Table>
      </div>

    </CustomModal>
  )
}